
    import {defineComponent, nextTick, reactive} from "vue";

import dateFormat from "../../utils/dateFormat";
import { calendarFormat} from "@/utils/dateFormat";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";

export default defineComponent({
  name: "InvitationItem",
  props: {
    invitationData: Object
  },

  setup(props) {
    const invitationList = reactive((props.invitationData as any).data || [])
    function doApplyOfInvitation(invitationId: string) {
        Modal({
            title: "温馨提示",
            content: "确认提交奖品申请？",
            onConfirm: () => {
                axios
                    .post(`/M/User/doApplyOfInvitation?invitationId=${invitationId}`,{
                        params: {
                            validate: true,
                        },
                    })
                    .then((res) => {
                        if (res.data.success) {
                            Toast({
                                type: "success",
                                title: res.data.msg,
                            });

                        } else {
                            Modal({
                                title: "提示",
                                content: res.data.msg,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            },
            onClose: () => {
                console.log("取消");
            },
        });







    }
    return {
      dateFormat,
      calendarFormat,
      invitationList,
      doApplyOfInvitation,
    };
  }
});
