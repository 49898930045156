<template>
  <transition-group tag="ul" name="list" class="record_ul"  v-for="(item, index) in invitationList" :key="index">
      <li>
        <img class="record_img" :src="item.beReferrerUserPic?item.beReferrerUserPic:'https://shijiaoke.oss-cn-beijing.aliyuncs.com/public/not_loaded.jpg'" alt="">
        <div class="record_progress">
          <p class="record_name" v-if="item.beReferrerRemark">{{item.beReferrerRemark}}</p>
          <p class="record_name" v-else>{{item.beReferrerUserName}}</p>
          <p class="record_rate">
              <span  class="bar" :style="'--precent:'+item.beReferrerOrderProcess.replace('%','')"></span>
              <span style="margin-left: 8px;color:#df0024" v-if="item.beReferrerOrderProcess == '100%' && item.rewardGive==0" @click="doApplyOfInvitation(item.id)">领取</span>
              <span style="margin-left: 8px;color:#999" v-else-if="item.beReferrerOrderProcess == '100%' && item.rewardGive!=0">已领取</span>
              <span style="margin-left: 8px;color:#999" v-else>未完成</span>
          </p>
        </div>
        <span class="record_award">
            <img v-if="item.beReferrerOrderProcess == '100%'" src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/5e7787347020f19c4b9ac3903dc2dcf4.png" alt="">
            <img v-else  src="https://shijiaoke.oss-cn-beijing.aliyuncs.com/News/d1c31df8cfc8b89150752d51696e59cd.png" alt="">
        </span>
      </li>
  </transition-group>
</template>



<script lang="ts">
    import {defineComponent, nextTick, reactive} from "vue";

import dateFormat from "../../utils/dateFormat";
import { calendarFormat} from "@/utils/dateFormat";
import axios from "@/api/axios";
import Modal from "@/components/UI/Modal";
import Toast from "@/components/UI/Toast";

export default defineComponent({
  name: "InvitationItem",
  props: {
    invitationData: Object
  },

  setup(props) {
    const invitationList = reactive((props.invitationData as any).data || [])
    function doApplyOfInvitation(invitationId: string) {
        Modal({
            title: "温馨提示",
            content: "确认提交奖品申请？",
            onConfirm: () => {
                axios
                    .post(`/M/User/doApplyOfInvitation?invitationId=${invitationId}`,{
                        params: {
                            validate: true,
                        },
                    })
                    .then((res) => {
                        if (res.data.success) {
                            Toast({
                                type: "success",
                                title: res.data.msg,
                            });

                        } else {
                            Modal({
                                title: "提示",
                                content: res.data.msg,
                            });
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    });
            },
            onClose: () => {
                console.log("取消");
            },
        });







    }
    return {
      dateFormat,
      calendarFormat,
      invitationList,
      doApplyOfInvitation,
    };
  }
});
</script>
<style lang="scss">
  .invitation_record .empty {
    /*background: #FFFFFF;*/
    padding-top: 60px !important;
  }
</style>

<style lang="scss" scoped>
main {
  padding-bottom: 50px;
}

.invitation_record{
  width:90%;
  height: 400px;
  margin:0 auto;
}
.record_ul{
  width:100%;
  height: auto;
}
.record_ul li{
  width:100%!important;
  height: 88px !important;
  background: #fff;
  margin-top: 10px;
  padding-bottom: 10px;
}
.record_img{
  width:47px;
  height: 47px;
  float:left;
  margin-left:15px;
  margin-top: 20px;
  border-radius: 50%;
}
.record_progress{
  width:67%;
  height: 50px;
  margin-left:10px;
  margin-top:20px;
  float:left;
}
.record_name{
  color:rgb(61,194,214);
  font-size: 12px;
  text-align: left;
  line-height: 20px;
  height: 20px;
}
.record_rate{
  width: 100%;
  height: 25px;
  line-height: 25px;
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
}
.record_award{
  width:47px;
  display: inline-block;
  float:right;
  margin-top: 20px;
}
.record_award img{
  height:50px;

}

.bar{
  width:82%;
  background: #f2f2f2;
  display: inline-block;
  border-radius: 20px;
  line-height: 20px;
  height: 18px;
}
.bar::before{
  display: inline-block;
  height: 18px;
  counter-reset: progress var(--precent);
  content: counter(progress)'%';
  width:calc(1% * var(--precent));
  color:#fff;
  background: #df0024;
  text-align: center;
  //white-space: pre-line;
  //overflow: hidden;
  border-radius: 20px;
  vertical-align: text-bottom;
}

</style>
