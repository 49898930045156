
import { App } from 'vue'
import Modal from './src/modal'



(Modal as any).install = (app: App): void => {
    app.config.globalProperties.$Modal = Modal
}

export default Modal
